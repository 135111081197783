.privacy-policy {
  background: $ui-base-color;
  padding: 20px;

  @media screen and (min-width: $no-gap-breakpoint) {
    border-radius: 4px;
  }

  &__body {
    margin-top: 20px;
  }
}

.prose {
  color: $secondary-text-color;
  font-size: 15px;
  line-height: 22px;

  p,
  ul,
  ol {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  img {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  video {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  figure {
    margin-top: 2em;
    margin-bottom: 2em;

    figcaption {
      font-size: 0.875em;
      line-height: 1.4285714;
      margin-top: 0.8571429em;
    }
  }

  figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.33;
  }

  h2 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  ol {
    counter-reset: list-counter;
  }

  li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  ol > li {
    counter-increment: list-counter;

    &::before {
      content: counter(list-counter) ".";
      position: absolute;
      left: 0;
    }
  }

  ul > li::before {
    content: "";
    position: absolute;
    background-color: $darker-text-color;
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: 0.5em;
    left: 0.25em;
  }

  ul > li,
  ol > li {
    position: relative;
    padding-left: 1.75em;
  }

  & > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  & > ul > li > *:first-child {
    margin-top: 1.25em;
  }

  & > ul > li > *:last-child {
    margin-bottom: 1.25em;
  }

  & > ol > li > *:first-child {
    margin-top: 1.25em;
  }

  & > ol > li > *:last-child {
    margin-bottom: 1.25em;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  b {
    color: $primary-text-color;
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  a {
    color: $highlight-text-color;
    text-decoration: underline;

    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  code {
    font-size: 0.875em;
    background: darken($ui-base-color, 8%);
    border-radius: 4px;
    padding: 0.2em 0.3em;
  }

  hr {
    border: 0;
    border-top: 1px solid lighten($ui-base-color, 4%);
    margin-top: 3em;
    margin-bottom: 3em;
  }

  hr + * {
    margin-top: 0;
  }

  h2 + * {
    margin-top: 0;
  }

  h3 + * {
    margin-top: 0;
  }

  h4 + *,
  h5 + *,
  h6 + * {
    margin-top: 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
